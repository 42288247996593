html,
body,
#container,
.dialog-backdrop,
.bigOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
  font-family: Helvetica, sans-serif;
  user-select: none;
  overflow: hidden;
}

body,
.bigOverlay {
  background-color: #2f3136;
}

.bigOverlay {
  z-index: 999;
  height: 100%;
  font-size: 3em;
  color: white;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
}

.dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1000;
}

.dialog {
  background-color: #445;
  color: #eee;
  min-width: 500px;
  max-width: 80%;
  border-radius: 5px;
  padding: 12px 28px;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%, 0%);
  max-height: 70vh;
  height: auto;
  overflow: auto;
  text-align: center;
}
.dialog.closable {
  max-width: calc(100% + 16px * 2);
  max-height: calc(70vh + 12px * 2);
  min-width: calc(500px + 28px * 2);
  overflow: hidden;
  padding: 0;
}
.dialog.closable .close {
  position: absolute;
  right: 16px;
  top: 12px;
  cursor: pointer;
  font-size: 2em;
}
.dialog.closable .innerContent {
  padding: 12px 40px;
  overflow: auto;
  box-sizing: border-box;
  max-height: inherit;
}

.dialog-whatsNew h1 {
  margin-bottom: 0;
  text-align: center;
}
.dialog-whatsNew h2 {
  text-decoration: underline;
  margin: 0;
  padding: 0;
  font-size: 1em;
  margin-top: 16px;
  text-align: left;
}
.dialog-whatsNew ul {
  text-align: left;
  margin: 8px 0;
  padding-left: 24px;
}
.dialog-whatsNew ul li {
  margin: 4px 0;
}

.dialog-supporters .innerContent {
  padding: 24px 24px !important;
}
.dialog-supporters h1 {
  margin-top: 0;
}

.dialog-render .pleaseWait {
  color: #cc0000;
  font-style: italic;
  margin-top: 4px;
}
.dialog-render .option,
.dialog-render .save {
  background: #333;
  color: #eee;
  border: 0;
  padding: 10px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-sizing: border-box;
}
.dialog-render .optionBar {
  width: 100%;
  overflow: hidden;
}
.dialog-render .optionBar .option {
  border: 1px solid #aaa;
  border-radius: 0;
  border-left-width: 0;
  border-right-width: 0;
  margin-left: 0;
  margin-right: 0;
  padding: 6px;
  display: inline-block;
  margin-bottom: 4px;
  margin-top: 8px;
}
.dialog-render .optionBar .option:first-of-type {
  border-left-width: 1px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.dialog-render .optionBar .option:last-of-type {
  border-right-width: 1px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.dialog-render .optionBar .option.toggled {
  background: #eee;
  color: #333;
}
.dialog-render .image {
  max-width: 300px;
  min-width: 300px;
  margin: 8px 0;
}
.dialog-render .header {
  margin: 0;
  font-weight: normal;
  font-size: 32px;
}

.progressBar {
  height: 32px;
  border: 1px solid #aaa;
  margin: 8px 0;
  border-radius: 3px;
}
.progressBar .progress {
  height: 100%;
  width: 0;
  background: #5588ff;
  border-radius: 3px;
}

.dialog-tutorial h1 {
  margin: 8px 0;
  font-size: 32px;
}
.dialog-tutorial .image {
  max-width: 300px;
  min-width: 300px;
  margin: 16px 0 12px 0;
}

#container {
  z-index: 0;
  height: calc(100% - 40px);
}

.link, #footer .link {
  text-decoration: underline;
  cursor: pointer;
  color: #ddd;
}
.link:hover, #footer .link:hover {
  color: white;
}

#footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  line-height: 40px;
  color: white;
  padding: 0 8px;
  background: #555;
  font-size: 16px;
  font-family: Helvetica;
  z-index: 1;
}
#footer * {
  display: inline-block;
  color: white;
  position: relative;
  top: 1px;
}
#footer .donate {
  top: -1px;
  margin-top: 4px;
  margin-left: 0;
  float: left;
  height: 32px;
  background-color: #e85b46;
  border-radius: 128px;
  padding: 0px 12px;
}
#footer .donate .patreon-icon {
  height: 16px;
  width: 16px;
  color: white;
  position: relative;
  top: -4px;
}
#footer .donate .patreon-icon svg {
  fill: white;
}
#footer .donate .patreon-text {
  position: relative;
  top: -4px;
}
#footer div {
  height: 40px;
  line-height: 40px;
}
#footer #footerLeft {
  position: absolute;
  top: 0;
  margin-left: 12px;
}
#footer #footerRight {
  position: absolute;
  right: 0;
  top: 0;
}
#footer #footerRight > * {
  margin-right: 12px;
}
#footer #closeFooter {
  cursor: pointer;
}

#f {
  position: absolute;
  left: 800px;
  z-index: 1;
}

.cropView {
  user-select: none;
  position: absolute;
  top: 0;
  overflow: auto;
  width: 700px;
  height: 100%;
  background: transparent;
}
.cropView .image,
.cropView canvas {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
}
.cropView canvas {
  z-index: 1;
}

.menu {
  position: absolute;
  right: 0;
  top: 0;
  color: #eee;
  width: 256px;
  max-height: 100%;
  z-index: 3;
  overflow-y: auto;
  overflow-x: hidden;
}
.menu .item,
.menu .item > button {
  pointer-events: all;
  background-color: #111;
  text-align: center;
  text-align-last: center;
  cursor: pointer;
  color: #eee;
  text-decoration: none;
  border: 0;
  font-size: 18px;
  font-family: Helvetica;
  height: 32px;
  line-height: 32px;
  width: 100%;
  padding: 0;
  margin: 0;
  box-sizing: content-box;
  float: left;
  position: relative;
}
.menu .item.half,
.menu .item > button.half {
  width: 50%;
}
.menu .item.toggled,
.menu .item > button.toggled {
  background: #eee;
  color: #333;
}
.menu .item:hover,
.menu .item > button:hover {
  border: 1px solid #aaa;
  line-height: 30px;
  box-sizing: border-box;
}
.menu .item:hover > button,
.menu .item > button:hover > button {
  height: 30px;
  line-height: 30px;
}
.menu .item .openInput,
.menu .item > button .openInput {
  display: none;
}
.menu .item.zoomBar > button,
.menu .item > button.zoomBar > button {
  width: 48px;
  float: right;
  background-color: #444;
}
.menu .item.zoomBar > button:hover,
.menu .item > button.zoomBar > button:hover {
  background: #eee;
  color: #333;
}
.menu .item.zoomBar .zoomLabel,
.menu .item > button.zoomBar .zoomLabel {
  display: inline;
}
.menu .item#borderSelect.edit,
.menu .item > button#borderSelect.edit {
  width: calc(100% - 32px);
}
.menu .item.borderEdit,
.menu .item > button.borderEdit {
  width: 32px;
  padding: 0;
}
.menu .item.render,
.menu .item > button.render {
  font-weight: bold;
  background: #ffc345;
  color: #111;
}

.dialog-backdrop.gradientEdit {
  text-align: center;
}
.dialog-backdrop.gradientEdit .dialog {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1024px) {
  .dialog-backdrop.gradientEdit .dialog {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    border-radius: 0;
    height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    transform: none;
  }
}
@media (max-width: 824px) {
  .dialog-backdrop.gradientEdit .dialog {
    flex-direction: column;
  }
  .dialog-backdrop.gradientEdit .dialog .leftCol {
    margin-right: 0;
    margin-bottom: 16px;
  }
}
.dialog-backdrop.gradientEdit .leftCol {
  margin-right: 16px;
}
.dialog-backdrop.gradientEdit .rightCol {
  flex-grow: 2;
}
.dialog-backdrop.gradientEdit .leftCol > *, .dialog-backdrop.gradientEdit .rightCol > * {
  margin-top: 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}
.dialog-backdrop.gradientEdit .leftCol > *:first-child, .dialog-backdrop.gradientEdit .rightCol > *:first-child {
  margin-top: 0;
}
.dialog-backdrop.gradientEdit canvas {
  border: 2px solid #111;
  box-sizing: border-box;
}
.dialog-backdrop.gradientEdit canvas.preview {
  margin-top: 0;
  width: 250px;
  display: inline-block;
}
.dialog-backdrop.gradientEdit .labelSlider {
  position: relative;
  height: 32px;
  line-height: 32px;
  background-color: #111;
  color: #eee;
  border: 1px solid #aaa;
}
.dialog-backdrop.gradientEdit .labelSlider:hover {
  background-color: #222;
}
.dialog-backdrop.gradientEdit button, .dialog-backdrop.gradientEdit select, .dialog-backdrop.gradientEdit input[type=color] {
  cursor: pointer;
  background-color: #111;
  color: white;
  border: 1px solid #aaa;
  padding: 4px;
  box-sizing: border-box;
}
.dialog-backdrop.gradientEdit button:hover, .dialog-backdrop.gradientEdit select:hover, .dialog-backdrop.gradientEdit input[type=color]:hover {
  background-color: #333;
}
.dialog-backdrop.gradientEdit input[type=color] {
  height: 32px;
  margin-top: 6px;
}
.dialog-backdrop.gradientEdit .gradientSlider {
  display: inline-block;
  width: auto;
}
.dialog-backdrop.gradientEdit .row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
}
.dialog-backdrop.gradientEdit .row .ok {
  flex-grow: 1;
}
.dialog-backdrop.gradientEdit .row .label {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
}
.dialog-backdrop.gradientEdit .row > * {
  font-size: 16px;
  flex-grow: 1;
  margin: 0 4px;
}
.dialog-backdrop.gradientEdit .row > *:first-child {
  margin-left: 0;
}
.dialog-backdrop.gradientEdit .row > *:last-child {
  margin-right: 0;
}

.gradientSlider {
  position: relative;
  text-align: left;
}
.gradientSlider .stop {
  position: absolute;
  top: 0;
  width: 32px;
  height: 32px;
  border: 3px solid #111;
  box-sizing: border-box;
  cursor: pointer;
}
.gradientSlider .stop.current {
  outline: 1px solid gold;
}

.labelSlider .labelSlider-label {
  width: 100%;
  height: 100%;
}
.labelSlider .labelSlider-slider {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  margin: 0;
  box-sizing: border-box;
  border: 0;
  background: transparent;
  margin: 0px;
  opacity: 0.5;
}
.labelSlider .labelSlider-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 32px;
  cursor: pointer;
  background: transparent;
  border-radius: 0px;
  border: 0;
}
.labelSlider .labelSlider-slider::-webkit-slider-thumb {
  height: 32px;
  width: 32px;
  border-radius: 0px;
  background: #eeeeee;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 0px;
}
.labelSlider .labelSlider-slider::-moz-range-track {
  width: 100%;
  height: 32px;
  cursor: pointer;
  background: transparent;
  border-radius: 0px;
  border: 0;
}
.labelSlider .labelSlider-slider::-moz-range-thumb {
  border: 0;
  height: 32px;
  width: 32px;
  border-radius: 0px;
  background: #eeeeee;
  cursor: pointer;
}
.labelSlider .labelSlider-slider::-ms-track {
  width: 100%;
  height: 32px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.labelSlider .labelSlider-slider::-ms-thumb {
  border: 0;
  width: 32px;
  border-radius: 0px;
  background: #eeeeee;
  cursor: pointer;
  height: 32px;
}

.previews {
  position: absolute;
  right: 0;
  bottom: 16px;
  background: transparent;
}
.previews .preview {
  bottom: -2px;
  overflow: hidden;
}
.previews .preview .onlineIndicator {
  z-index: 2;
  position: absolute;
  left: 18px;
  top: 20px;
}
.previews .preview .bottomBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.previews .preview .bottomBar .size {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 32px;
  padding: 0;
  margin: 0;
  background-color: #eee;
  border-right: 1px solid #aaa;
  border-top: 1px solid #aaa;
  height: 32px;
  padding: 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  line-height: 32px;
  z-index: 2;
}
.previews .preview .bottomBar .remove {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 0;
  background: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background-color: #eee;
  width: 32px;
  height: 32px;
  line-height: 32px;
  z-index: 3;
  border-top: 1px solid #aaa;
  box-sizing: border-box;
}

.paypal__submit-image {
  border: 0;
  background: transparent;
  outline: none;
}

@media (max-width: 800px) {
  /*.dialog-render img, .dialog-tutorial img
    {
        max-width: 87%;
        min-width: 87%;
    }*/
  .dialog-backdrop .dialog {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    top: 0;
    left: 0;
    transform: unset;
    box-sizing: border-box;
    margin: 0;
    border-radius: 0;
    text-align: center;
  }

  .dialog-render .save {
    margin-bottom: 8px;
  }
}
@media (max-width: 940px) {
  #link-whatsNew {
    display: none;
  }
}
@media (max-width: 770px) {
  #link-discord {
    display: none;
  }
}
@media (max-width: 700px) {
  #link-help {
    display: none;
  }
}
@media (max-width: 570px) {
  #footerLeft {
    display: none;
  }
}
@media (max-width: 450px) {
  #footer {
    display: none;
  }

  #container,
#bigOverlay {
    height: 100%;
  }
}
@media (max-width: 400px) {
  .dialog-render .image,
.dialog-tutorial .image {
    max-width: 100%;
    min-width: 100%;
  }
}
@media (max-width: 375px) {
  #footer {
    display: none;
  }

  #container,
#bigOverlay {
    height: 100%;
  }
}
/*# sourceMappingURL=index.6e693adf.css.map */
